:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.manual {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 40px 40px;
  grid-gap: 10px;
  grid-template-areas: "input btn-dmg" "input btn-heal";
  margin-bottom: 30px;
}
.manual .heal {
  grid-area: btn-heal;
}
.manual .dmg {
  grid-area: btn-dmg;
}
.manual .dmg,
.manual .heal {
  display: flex;
  justify-content: space-between;
}

.select-amount {
  display: grid;
  grid-auto-rows: 28px;
  grid-template-columns: 1fr repeat(2, max-content);
  row-gap: 2px;
}
.select-amount.has-defenses {
  grid-template-columns: 1fr repeat(3, max-content);
}
.select-amount .name {
  background: var(--neutral-8);
  padding: 5px;
}
.select-amount .defenses {
  background: var(--neutral-8);
  display: grid;
  grid-template-columns: 18px 18px 18px auto;
  grid-column-gap: 5px;
  user-select: none;
  line-height: 28px;
  padding-right: 10px;
}
.select-amount .defenses .type {
  padding-left: 10px;
}
.select-amount .defenses .option {
  cursor: pointer;
  position: relative;
  width: 18px;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  color: var(--neutral-2);
}
.select-amount .defenses .option span {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  width: 18px;
  line-height: 28px;
  top: 0;
  left: 0;
  color: var(--neutral-8);
}
.select-amount .defenses .option.active.i, .select-amount .defenses .option.active.r {
  color: var(--green);
}
.select-amount .defenses .option.active.v {
  color: var(--red);
}
.select-amount .defenses .option.active span {
  color: var(--neutral-1);
}
.select-amount .multipliers {
  display: flex;
  justify-content: flex-end;
  background: var(--neutral-8);
}
.select-amount .multipliers .multiplier {
  padding: 0 8px;
  margin-left: 1px;
  line-height: 28px;
  background: var(--neutral-5);
  color: var(--neutral-1);
  user-select: none;
  cursor: pointer;
}
.select-amount .value {
  line-height: 28px;
  padding-left: 8px;
  text-align: right;
  font-weight: bold;
  font-size: 15px;
  color: var(--blue);
}

[data-theme=light] .select-amount .defenses .option {
  color: var(--neutral-4);
}
[data-theme=light] .select-amount .defenses .option.active span {
  color: var(--neutral-11);
}