
.build-type {
	> h3 {
		text-align: center;
		font-size: 25px !important;
		margin: 40px 0 30px 0 !important;
	}

	.types {
		display: flex;
		justify-content: center;
		margin: -10px;

		.hk-card {
			max-width: 200px;
			margin: 10px;
			user-select: none;

			&.active {
				border-color: #2c97de !important;
			}
		}
	}
}
