:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
h3 {
  position: relative;
  color: var(--white);
  margin-bottom: 20px !important;
  z-index: 1;
}

.track {
  margin: auto;
  width: 100%;
  height: 100%;
  display: grid;
  z-index: 0;
}
.track.desktop {
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;
}
.track.desktop.isLive {
  grid-template-columns: 3fr 1fr minmax(200px, 250px);
}
.track.desktop.isLive .side {
  padding-right: 0;
}
.track.desktop.isLive .shares-bar {
  height: 100%;
}
.track.desktop.isLive .shares-bar .show {
  display: none;
}
.track.desktop.isLive .shares-bar .shares {
  width: 100%;
}
.track.desktop .players {
  padding: 30px 0 0 15px;
  overflow: hidden;
}
.track.desktop .players .q-scrollarea {
  height: calc(100% - 55px);
}
.track.desktop .players .q-scrollarea > div {
  padding-right: 6px;
}
.track.desktop .players .q-scrollarea::v-deep .top-menu {
  border-bottom: solid 2px var(--white);
  padding-bottom: 2px;
  margin-bottom: 23px;
}
.track.desktop .players .q-scrollarea::v-deep .top-menu .money {
  text-shadow: 0 0 3px var(--black);
  color: var(--white);
}
.track.desktop .side {
  padding: 30px 15px 0 0;
  overflow: hidden;
}
.track.desktop .side .q-scrollarea {
  height: calc(100% - 55px);
}
.track.desktop .side .q-scrollarea.during-encounter {
  height: calc(100% - 50px);
}
.track.desktop .side .q-scrollarea .meters-wrapper {
  padding-top: 15px;
}
.track.mobile {
  grid-template-rows: 60px 1fr;
  grid-template-columns: 1fr;
}
.track.mobile .transparent-bg {
  background: none;
}
.track.mobile .q-tab-panel {
  padding: 15px;
}
.track::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 900px) {
  .track.desktop {
    grid-template-columns: 2fr 1fr !important;
  }
  .track.desktop.isLive .side {
    padding-right: 15px !important;
  }
  .track.desktop.isLive .shares-bar {
    width: 250px;
    position: absolute;
    right: -250px;
    display: flex;
    justify-content: center;
    transition: all 0.5s linear;
    z-index: 2;
  }
  .track.desktop.isLive .shares-bar .show {
    background-color: var(--blue);
    color: var(--neutral-1);
    display: block;
    width: 18px;
    text-align: center;
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
  .track.desktop.isLive .shares-bar .show i {
    transition: all 0.3s linear;
  }
  .track.desktop.isLive .shares-bar.shown {
    right: 0;
  }
  .track.desktop.isLive .shares-bar.shown .show i {
    transform: rotate(180deg);
  }
}
@media only screen and (max-width: 992px) {
  .track.desktop {
    grid-template-columns: 3fr 2fr;
  }
  .track.desktop.isLive {
    grid-template-columns: 3fr 1fr minmax(180px, 200px);
  }
}
@media only screen and (min-width: 1250px) {
  .track.desktop {
    grid-gap: 30px;
  }
  .track.desktop.isLive {
    grid-template-columns: 3fr 1fr minmax(250px, 300px);
  }
  .track.desktop .players {
    padding-left: 30px;
  }
  .track.desktop .side {
    padding-right: 30px;
  }
}