
.container-fluid {
	padding: 20px;

	.container {
		max-width: 600px;

		img {
			width: 80%;
			margin: 40px 10%;
		}
		.card {
			.card-footer {
				padding: 0;
			}
		}
	}
}
