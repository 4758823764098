:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.hk-card {
  position: sticky;
  top: 0;
  margin-top: 48px;
}
.hk-card .card-header {
  padding: 0;
  justify-content: flex-start;
  line-height: 20px;
  font-size: 12px;
}
.hk-card .card-header .image {
  height: 62px;
  width: 62px;
  border-right: solid 1px var(--neutral-5);
  color: var(--neutral-2);
  background-color: var(--neutral-9);
  text-align: center;
  line-height: 62px;
  font-size: 45px;
  margin-right: 10px;
  background-position: center top;
  background-size: cover;
}
.hk-card .computed {
  padding: 15px;
}
.hk-card .computed h4 {
  font-size: 22px;
  margin: 0px;
}
.hk-card .computed .abilities {
  display: flex;
  justify-content: space-between;
  text-align: center;
  user-select: none;
  width: 100%;
  margin: 20px 0;
}
.hk-card .computed .abilities > div {
  cursor: pointer;
}
.hk-card .computed .abilities > div .ability,
.hk-card .computed .abilities > div .score {
  font-size: 15px;
  text-transform: uppercase;
  height: 15px;
  margin: 0;
  color: var(--neutral-2);
}
.hk-card .computed .abilities > div .mod {
  height: 55px;
  line-height: 60px;
  font-size: 30px;
  font-weight: bold;
}
.hk-card .computed .stats {
  display: flex;
  justify-content: space-between;
  user-select: none;
}
.hk-card .computed .stats .armor_class,
.hk-card .computed .stats .hit_points,
.hk-card .computed .stats .speed,
.hk-card .computed .stats .initiative {
  text-align: center;
  cursor: pointer;
}
.hk-card .computed .stats .armor_class h6,
.hk-card .computed .stats .hit_points h6,
.hk-card .computed .stats .speed h6,
.hk-card .computed .stats .initiative h6 {
  font-size: 12px;
  text-transform: uppercase;
  height: 15px;
  line-height: 15px;
  margin: 0;
  color: var(--neutral-2);
}
.hk-card .computed .stats .armor_class .value,
.hk-card .computed .stats .hit_points .value,
.hk-card .computed .stats .speed .value,
.hk-card .computed .stats .initiative .value {
  height: 40px;
  line-height: 40px;
  font-size: 35px;
  font-weight: bold;
}
.hk-card .computed .stats .armor_class .ft,
.hk-card .computed .stats .hit_points .ft,
.hk-card .computed .stats .speed .ft,
.hk-card .computed .stats .initiative .ft {
  font-size: 15px;
}
.hk-card .computed .list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}
.hk-card .computed .list li {
  display: flex;
  justify-content: space-between;
  line-height: 35px;
  border-bottom: solid 1px var(--neutral-3);
}
.hk-card .computed .list li .value {
  font-size: 20px;
}
.hk-card .computed .columns {
  height: 128px;
  column-count: 2;
  column-gap: 15px;
  column-rule: 1px solid var(--neutral-3);
}
.hk-card .computed .advantage {
  color: #83b547 !important;
}
.hk-card .computed .advantage:hover .int {
  color: #83b547 !important;
}
.hk-card .computed .disadvantage {
  color: #cc3e4a;
}
.hk-card .computed .disadvantage:hover .int {
  color: #cc3e4a !important;
}
.hk-card .computed .neutral:hover .int {
  color: inherit !important;
}