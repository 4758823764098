:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.light {
  width: 100%;
  height: 100%;
}
.light::-webkit-scrollbar {
  display: none;
}
.light .weather-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  animation-direction: alternate-reverse;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: 1;
}
.light .weather-wrapper::v-deep .wrapper {
  pointer-events: none;
}
.light .weather-wrapper::before, .light .weather-wrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-image: var(--backgroundImage);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: -2;
}
.light .weather-wrapper .bg-video,
.light .weather-wrapper .youtube-player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  z-index: -2;
  transition: all 0.3s linear;
}
.light .weather-wrapper.lightning-effect__light {
  animation: lightnings 360s linear infinite;
}
@keyframes lightnings {
  0% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  0.87% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  0.9% {
    filter: brightness(2);
    opacity: 0.3;
    background-color: var(--neutral-1);
  }
  0.93% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  0.95% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  0.99% {
    filter: brightness(2);
    opacity: 0.3;
    background-color: var(--neutral-1);
  }
  1% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  100% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
}
.light .weather-wrapper.lightning-effect__medium {
  animation: lightningm 180s linear infinite;
}
@keyframes lightningm {
  0% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  0.85% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  0.9% {
    filter: brightness(2);
    opacity: 0.3;
    background-color: var(--neutral-1);
  }
  0.95% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  1% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  1.05% {
    filter: brightness(2);
    opacity: 0.3;
    background-color: var(--neutral-1);
  }
  1.1% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  100% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
}
.light .weather-wrapper.lightning-effect__heavy {
  animation: lightning 60s linear infinite;
}
@keyframes lightning {
  0% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  1.8% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  1.9% {
    filter: brightness(2);
    opacity: 0.3;
    background-color: var(--neutral-1);
  }
  2% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  2.4% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  2.6% {
    filter: brightness(2);
    opacity: 0.3;
    background-color: var(--neutral-1);
  }
  2.9% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
  100% {
    filter: brightness(1);
    opacity: 1;
    background-color: unset;
  }
}
.light .weather-wrapper.quake {
  background-image: var(--backgroundImage);
}
.light .weather-wrapper.quake-light::after {
  animation: quake-l 180s ease-in infinite;
  opacity: 0.6;
}
@keyframes quake-l {
  0% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
  .04% {
    transform: translate(8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .08% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .12% {
    transform: translate(0px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  .16% {
    transform: translate(8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .20% {
    transform: translate(-8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .24% {
    transform: translate(0px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .28% {
    transform: translate(8px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  .32% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .36% {
    transform: translate(0px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .40% {
    transform: translate(8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .44% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .48% {
    transform: translate(0px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  .52% {
    transform: translate(8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .56% {
    transform: translate(-8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .60% {
    transform: translate(0px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .64% {
    transform: translate(8px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  .68% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .72% {
    transform: translate(0px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .76% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
}
.light .weather-wrapper.quake-medium::after {
  animation: quake-m 60s ease-in infinite;
  opacity: 0.6;
}
@keyframes quake-m {
  0% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
  .11% {
    transform: translate(8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .22% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .33% {
    transform: translate(0px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  .44% {
    transform: translate(8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .55% {
    transform: translate(-8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  .66% {
    transform: translate(0px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .77% {
    transform: translate(8px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  .88% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  .99% {
    transform: translate(0px, 0) rotate(1deg);
    filter: blur(3px);
  }
  1.11% {
    transform: translate(8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  1.22% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  1.33% {
    transform: translate(0px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  1.44% {
    transform: translate(8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  1.55% {
    transform: translate(-8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  1.66% {
    transform: translate(0px, 0) rotate(0deg);
    filter: blur(1px);
  }
  1.77% {
    transform: translate(8px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  1.88% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  1.99% {
    transform: translate(0px, 0) rotate(1deg);
    filter: blur(3px);
  }
  2% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
}
.light .weather-wrapper.quake-heavy::after {
  animation: quake-h 0.6s ease-in infinite;
  opacity: 0.6;
}
@keyframes quake-h {
  0% {
    transform: translate(0, 0) rotate(0deg);
    filter: blur(1px);
  }
  11% {
    transform: translate(8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  22% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  33% {
    transform: translate(0px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  44% {
    transform: translate(8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  55% {
    transform: translate(-8px, 0) rotate(1deg);
    filter: blur(3px);
  }
  66% {
    transform: translate(0px, 0) rotate(0deg);
    filter: blur(1px);
  }
  77% {
    transform: translate(8px, 0) rotate(-1deg);
    filter: blur(3px);
  }
  88% {
    transform: translate(-8px, 0) rotate(0deg);
    filter: blur(1px);
  }
  99% {
    transform: translate(0px, 0) rotate(1deg);
    filter: blur(3px);
  }
}

@keyframes imagezoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.25);
  }
}