:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.top {
  background-color: var(--neutral-11);
}
.top .container {
  max-width: 1280px;
  padding: 22px 20px 50px 20px;
}
.top .container .shieldmaiden {
  transform: scaleX(-1);
  object-fit: cover;
  object-position: top 0 left -30px;
  height: 250px;
  width: 100%;
  margin-bottom: 22px;
}
.top .container .laptop {
  display: flex;
  justify-content: center;
}
.top .container .laptop img {
  width: 90%;
  margin-bottom: 50px;
}
.top .container h1 {
  font-size: 35px;
  margin: 0 0 10px 0;
  max-width: 550px;
  font-weight: bold;
  line-height: 45px;
}
.top .container h2 {
  margin: 0 0 22px 0;
  font-size: 18px;
  line-height: 30px;
  max-width: 400px;
  opacity: 0.8;
  font-weight: normal;
}
.top .container .buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 35px;
}
.top .container .buttons .q-btn {
  width: 100%;
}
.top .container .buttons .q-btn.try-demo {
  border: solid 1px var(--accent);
  background-color: var(--neutral-11);
}
.top .container .tools-title {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}
.top .container .tools {
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}
.top .container .tools .tool {
  border-radius: var(--border-radius);
  background-color: var(--neutral-6);
  color: var(--neutral-1);
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  padding: 15px 10px;
}
.top .container .tools .tool__image {
  min-width: 40px;
  font-size: 20px;
  text-align: center;
}
.top .container .tools .tool__content {
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 768px) {
  .top .container {
    padding: 95px 20px 77px 20px;
    background-image: url("../../assets/_img/shieldmaiden.webp");
    background-repeat: no-repeat;
    background-position: top 50px right -120px;
  }
  .top .container .shieldmaiden {
    display: none;
  }
  .top .container .buttons {
    flex-direction: row;
    margin-bottom: 77px;
  }
  .top .container .buttons .q-btn {
    width: unset;
  }
  .top .container .tools {
    gap: 4px;
  }
  .top .container .tools .tool {
    background: none;
    padding: 3px 0;
  }
  .top .container .tools .tool:hover .tool__content {
    font-size: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .top .container {
    padding: 145px 30px 77px 30px;
    background-position: top 55px right -20px;
  }
  .top .container h2 {
    max-width: 500px;
  }
  .top .container .tools {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 2px;
    max-width: 600px;
  }
  .top .container .tools .tool {
    flex-basis: 250px;
    padding: 8px 0;
  }
}
@media only screen and (min-width: 1200px) {
  .top .container {
    background-position: top 10px right 85px;
  }
  .top .container h2 {
    max-width: 600px;
  }
  .top .container .tools {
    column-gap: 60px;
    max-width: 600px;
  }
  .top .container .tools .tool {
    flex-basis: 250px;
  }
}
[data-theme=light] .top {
  background-color: var(--neutral-6);
}