:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.initiative-wrapper {
  overflow: hidden;
  height: 100%;
}
.initiative-wrapper .actions {
  display: flex;
  justify-content: space-between;
  height: 35px;
  line-height: 35px;
  border-bottom: solid 2px var(--white);
  color: var(--white);
}
.initiative-wrapper .actions .right a {
  color: var(--neutral-1) !important;
  margin-left: 10px;
}
.initiative-wrapper .q-scrollarea {
  height: calc(100% - 49px);
}
.initiative-wrapper .q-scrollarea > div {
  padding-right: 6px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list {
  margin: 20px 0;
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 5px;
  user-select: none;
  table-layout: fixed;
}
.initiative-wrapper .q-scrollarea > div .initiative-list th.init {
  text-align: center;
  width: 38px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list th.ac {
  text-align: center;
  width: 46px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list th.image {
  width: 46px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list th.conditions {
  max-width: 200px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr.top td {
  font-size: 15px;
  padding: 10px 0 5px 0;
  border: none;
  border-bottom: solid 1px var(--white);
  cursor: default;
  color: var(--white) !important;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr.top td:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr {
  cursor: pointer;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td {
  background: var(--neutral-8-transparent-8);
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
  backdrop-filter: blur(1px);
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td:first-child {
  border-left: solid 1px transparent;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td:last-child {
  border-right: solid 1px transparent;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.init {
  width: 38px;
  text-align: center;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.ac {
  padding: 0;
  width: 45px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.ac .ac_wrapper {
  height: 44px;
  position: relative;
  color: var(--neutral-4);
  text-align: center;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.ac .ac_wrapper i {
  width: 100%;
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 35px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.ac .ac_wrapper .value {
  font-weight: bold;
  filter: invert(1) grayscale(1) brightness(1.3) contrast(9000);
  mix-blend-mode: luminosity;
  opacity: 0.95;
  line-height: 40px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.hp {
  white-space: nowrap;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.image {
  padding: 0;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.image .img {
  width: 44px;
  height: 44px;
  font-size: 32px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.conditions {
  max-width: 200px;
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr.targeted td {
  border-color: var(--blue);
}
@media only screen and (min-width: 576px) {
  .initiative-wrapper .q-scrollarea > div .initiative-list tbody tr:hover td {
    border-color: var(--blue);
  }
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td:first-child,
.initiative-wrapper .q-scrollarea > div .initiative-list tbody thead th {
  color: var(--neutral-1);
  background: none;
  text-shadow: 0 0 3px var(--black);
}
.initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td:first-child,
.initiative-wrapper .q-scrollarea > div .initiative-list tbody thead th:first-child {
  text-align: center;
}
.initiative-wrapper .q-scrollarea > div .conditions {
  padding: 9px 10px;
}
.initiative-wrapper .q-scrollarea > div .conditions .condition {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.initiative-wrapper .q-scrollarea > div .conditions .condition .n {
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  color: var(--red);
  top: -5px;
  left: 2px;
}
.initiative-wrapper .q-scrollarea > div .entities-move {
  transition: transform 0.6s;
}

@media only screen and (max-width: 576px) {
  .initiative-wrapper {
    padding-bottom: 60px;
  }
  .initiative-wrapper .actions {
    position: fixed;
    bottom: 0;
    z-index: 90;
    background: var(--neutral-9);
    color: var(--neutral-1);
    left: 0;
    width: 100%;
    border: none;
    height: 60px;
    line-height: 60px;
    display: block;
  }
  .initiative-wrapper .q-scrollarea {
    height: 100%;
  }
  .initiative-wrapper .q-scrollarea .initiative-list {
    margin: 0 !important;
  }
  .initiative-wrapper .q-scrollarea .initiative-list th {
    padding: 5px 0;
  }
  .initiative-wrapper .q-scrollarea .initiative-list th.ac {
    width: 35px;
  }
  .initiative-wrapper .q-scrollarea .initiative-list tbody tr td {
    padding: 5px;
  }
  .initiative-wrapper .q-scrollarea .initiative-list tbody tr td.image {
    padding: 0;
    width: 43px;
    vertical-align: top;
  }
  .initiative-wrapper .q-scrollarea .initiative-list tbody tr td.image .img {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
  }
  .initiative-wrapper .q-scrollarea .initiative-list tbody tr td.init {
    width: 35px;
  }
  .initiative-wrapper .q-scrollarea .initiative-list tbody tr td.ac .ac_wrapper i {
    font-size: 22px;
  }
  .initiative-wrapper .q-scrollarea .initiative-list tbody tr td.ac .ac_wrapper .value {
    font-size: 18px;
  }
  .initiative-wrapper .q-tabs {
    height: 60px;
  }
}
@media only screen and (min-width: 1250px) {
  .initiative-wrapper .q-scrollarea > div .initiative-list {
    font-size: 25px;
  }
  .initiative-wrapper .q-scrollarea > div .initiative-list th.image {
    width: 67px;
  }
  .initiative-wrapper .q-scrollarea > div .initiative-list th.init {
    width: 55px;
  }
  .initiative-wrapper .q-scrollarea > div .initiative-list th.ac {
    width: 59px;
  }
  .initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.image .img {
    width: 67px;
    height: 67px;
    font-size: 48px;
  }
  .initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.ac .ac_wrapper i {
    top: 2px;
    font-size: 48px;
  }
  .initiative-wrapper .q-scrollarea > div .initiative-list tbody tr td.ac .ac_wrapper .value {
    line-height: 45px;
    font-size: 23px;
  }
  .initiative-wrapper .q-scrollarea > div .conditions {
    padding-right: 15px;
  }
  .initiative-wrapper .q-scrollarea > div .conditions .condition .img {
    width: 40px;
    height: 40px;
  }
}