:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
#turns {
  user-select: none;
  height: 60px;
  padding: 10px;
  line-height: 40px;
  background: var(--neutral-8-transparent);
  font-size: 20px;
  grid-area: turns;
  align-items: center;
  position: relative;
}
#turns .center {
  align-items: center;
}
#turns .live {
  cursor: pointer;
}
#turns .test {
  font-size: 15px;
  padding: 0 5px;
  line-height: 28px;
  background-color: var(--orange);
  border-radius: var(--border-radius);
}
#turns .edit {
  font-size: 28px;
}
#turns .edit i {
  vertical-align: -2px;
}
#turns .timer {
  margin-right: 20px;
}
#turns .handler {
  font-size: 25px;
  line-height: 40px;
  border-radius: var(--border-radius);
}
#turns .handler:hover {
  color: var(--blue) !important;
}
#turns .round-info {
  line-height: 12px;
  font-size: 11px;
  text-align: center;
}
#turns .round-info .number {
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
}
#turns .requests,
#turns .info {
  padding-top: 3px;
  width: 26px;
  margin: 0 15px;
  position: relative;
  cursor: pointer;
}
#turns .requests .notifications,
#turns .info .notifications {
  user-select: none;
  position: absolute;
  top: -1px;
  right: -3px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
#turns .requests .notifications div,
#turns .info .notifications div {
  position: absolute;
  width: inherit;
  height: inherit;
  line-height: 20px;
  text-align: center;
  font-size: 13px;
}
#turns .info {
  margin: 0;
  padding: 0;
  display: none;
  font-size: 28px;
  color: var(--neutral-3);
}
#turns .info .q-icon {
  vertical-align: -4px;
}

@media only screen and (max-width: 1000px) {
  #turns .live {
    display: none;
  }
  #turns .info {
    display: block;
  }
}
@media only screen and (max-width: 576px) {
  #turns .edit {
    color: var(--neutral-3);
  }
  #turns .round-info .header {
    margin-top: 17px;
  }
  #turns .round-info .number {
    font-weight: bold;
    font-size: 18px !important;
    line-height: 18px !important;
  }
  #turns .timer {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 0;
    font-size: 15px;
    position: absolute;
    line-height: 20px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: solid 1px var(--neutral-4);
    padding: 0 10px;
  }
  #turns .timer i {
    margin-right: 5px;
    line-height: 20px;
    font-size: 12px;
  }
}