
.skills {
	columns: 3;

	.skill {
		width: 100%;
		display: grid;
		grid-template-columns: 45px 1fr max-content;

		.abillity {
			text-transform: uppercase;
			text-align: center;
		}
		.mod {
			margin-left: 8px;
		}
	}
}
.modifiers {
	columns: 2;
}
@media only screen and (max-width: 1250px) { 
	.skills {
		columns: 2;
	}
}
@media only screen and (max-width: 890px) { 
	.skills {
		columns: 1;
	}
}
