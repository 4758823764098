
ul {
	margin-top: 20px;
	padding-left: 20px;

	li {
		margin-bottom: 20px;
	}

	&.exhaustion {
		list-style: none;
		padding: 0;
	}
}
