@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@import url("https://fonts.googleapis.com/css?family=Playfair+Display+SC:700&display=swap");

.monster {
	color: $black;
	font-family: Helvetica, sans-serif, serif;
	&::v-deep {
		.hk-roll {
			color: $black;
			&:hover {
				color: rgb(165, 42, 42);
			}
		}
	}

	.hk-compendium-image {
		column-span: all;
	}

	h2 {
		color: #6e1d10;
		text-transform: none;
		font-size: 32px;
		margin-bottom: 0;
		font-family: "Playfair Display SC", serif;
		font-weight: normal;

		.source {
			font-size: 15px;
			font-family: Helvetica, sans-serif, serif;
			color: $black;
		}
	}
	h3 {
		font-family: sans-serif;
		color: #6e1d10;
		border-bottom: solid 1px rgb(165, 42, 42);
		font-size: 20px;
		padding-bottom: 2px;
		margin-bottom: 5px;
	}
	p {
		margin-bottom: 10px;
	}
	.size {
		font-size: 18px;
		font-style: italic;
	}
	.attributes,
	.stats {
		color: #6e1d10;

		.saves {
			user-select: none;

			.save {
				cursor: pointer;
			}
			.advantage .save:hover {
				color: $green;
			}
			.disadvantage .save:hover {
				color: $red;
			}
		}
	}
	.skills {
		user-select: none;
		column-count: 3;
		column-gap: 20px;
		column-rule: 1px solid rgb(165, 42, 42);

		.skill {
			display: grid;
			grid-template-columns: 1fr max-content;
			column-gap: 5px;
			cursor: pointer;

			&:hover {
				color: rgb(165, 42, 42);
			}
			i {
				&.fa-circle {
					margin: 0 3px;
					font-size: 6px;
					vertical-align: 2px;
				}
			}
		}
		.advantage .skill:hover {
			color: $green;
		}
		.disadvantage .skill:hover {
			color: $red;
		}
	}
	.saves .hk-roll {
		&::after {
			content: ",";
			margin-right: 3px;
		}
		&:last-child::after {
			content: "";
		}
	}

	hr {
		border-top: 2px solid rgb(165, 42, 42);
		margin: 10px 0;
	}
	ul {
		padding-left: 20px;
	}
	.abilities {
		user-select: none;
		color: #6e1d10;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		font-size: 12px;
		margin: -10px;

		.abilityName {
			font-size: 15px;
			font-weight: bold;
		}
		.ability {
			margin: 10px;
			cursor: pointer;
		}
		.advantage .ability:hover {
			color: $green;
		}
		.disadvantage .ability:hover {
			color: $red;
		}
	}

	&.smallWidth {
		.abilities {
			grid-template-columns: repeat(3, auto);
			grid-template-rows: auto auto;
			grid-row-gap: 15px;
		}

		.skills {
			column-count: 2;
		}
	}
}
.roll-button {
	display: inline-block;
	cursor: pointer;
	background-image: url("../../assets/_img/logo/logo-icon-no-shield-cyan.svg");
	height: 20px;
	width: 20px;
	background-position: center;
	background-size: cover;
	vertical-align: -5px;
	user-select: none;
}
.advantage .roll-button:hover {
	background-image: url("../../assets/_img/logo/logo-icon-no-shield-green.svg");
}
.disadvantage .roll-button:hover {
	background-image: url("../../assets/_img/logo/logo-icon-no-shield-red.svg");
}
