
.q-tab-panel {
	padding: 15px 0;
}
.overflow-visible {
	overflow: visible;
	&::v-deep {
		.q-panel {
			overflow: visible;
		}
	}
}
