
ul.entities {
	li {
		padding: 10px;

		.member_info {
			display: flex;
			justify-content: space-between;
		}
		.trigger {
			text-align: center;
			padding: 5px;
			margin-top: 10px;
		}

		ul {
			padding: 0;
			list-style: none;

			li {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}
