:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.normal-view .top-menu {
  display: flex;
  justify-content: space-between;
}
.normal-view .players .col.header {
  color: var(--white);
  padding: 0 12px;
}

.money {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  grid-area: money;
  font-size: 18px;
  line-height: 31px;
}
.money div {
  margin-right: 10px;
}
.money div img {
  height: 14px;
}
.money div:last-child {
  margin: none;
}

.players {
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 1px;
  user-select: none;
}
.players .image {
  width: 49px;
  height: 49px;
  background-size: cover;
  background-position: top center;
  background-color: var(--neutral-9);
  border: solid 1px var(--neutral-2);
  position: relative;
  font-size: 36px;
  text-align: center;
  color: var(--neutral-2);
}
.players .image > i::before {
  vertical-align: 5px;
}
.players .image .transformed {
  right: 0;
  bottom: 0;
  position: absolute;
  background: var(--neutral-8);
  padding: 0 2px;
  border-left: solid 1px var(--neutral-2);
  border-top: solid 1px var(--neutral-2);
  font-size: 13px;
}
.players .col {
  min-height: 35px;
  padding: 12px 10px;
  background-color: var(--neutral-8);
  line-height: 25px;
}
.players .col.header {
  padding: 0 12px 5px 12px;
  background: none;
  color: var(--neutral-3);
  min-height: 25px;
}
.players .col.header.actions {
  padding-top: 5px;
}
.players .col.ac {
  text-align: center;
  font-weight: bold;
  position: relative;
  padding: 0;
  width: 45px;
}
.players .col.ac i,
.players .col.ac .value {
  left: 5px;
  position: absolute;
  line-height: 49px;
  width: 100%;
  text-align: center;
}
.players .col.ac i {
  font-size: 35px;
  color: var(--neutral-4);
}
.players .col.ac .value {
  font-weight: bold;
  color: var(--white);
  margin-top: -1px;
}
.players .col.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.players .col.health {
  text-align: right;
  padding: 8px 10px;
}
.players .col.health .current {
  font-weight: bold;
}
.players .col.health .hit-points {
  background-color: var(--neutral-9);
  border-radius: var(--border-radius-small);
  padding: 0 5px;
  line-height: 33px;
  height: 33px;
  display: inline-block;
}
.players .col.pp, .players .col.pinv, .players .col.pins, .players .col.save {
  text-align: center;
}
.players .col .saves .save {
  margin-left: 4px;
}
.players .col.actions {
  display: flex;
  justify-content: flex-end;
  padding: 9px 12px 9px 0;
}
.players.xp .image {
  width: 62px;
  height: 62px;
  grid-row: span 2;
  font-size: 49px;
}
.players.xp .xp-bar {
  background: var(--neutral-8-transparent-8);
  display: flex;
  justify-content: space-between;
  height: 15px;
  width: 100%;
  font-size: 12px;
}
.players.xp .xp-bar .level {
  display: block;
  width: min-content;
  padding: 0 3px;
  line-height: 15px;
  text-align: center;
}
.players.xp .xp-bar .q-linear-progress {
  margin-top: 6px;
  height: 3px;
}
.players.large {
  font-size: 25px;
}
.players.large .image {
  height: 61px;
  width: 61px;
  font-size: 49px;
}
.players.large .col {
  line-height: 30px;
  padding: 14px 15px;
}
.players.large .col.header {
  font-size: 18px;
}
.players.large .col.ac {
  padding: 0;
  width: 54px;
}
.players.large .col.ac i,
.players.large .col.ac .value {
  line-height: 61px;
}
.players.large .col.ac i {
  font-size: 45px;
}
.players.large .col.actions {
  padding: 14px 15px 14px 0;
}
.players.large.xp .image {
  width: 84px;
  height: 84px;
  font-size: 67px;
}
.players.large.xp .xp-bar {
  height: 23px;
  font-size: 18px;
}
.players.large.xp .xp-bar .q-linear-progress {
  margin-top: 9px;
  height: 5px;
}
.players.large.xp .xp-bar .level {
  line-height: 22px;
}

[data-theme=light] .players .image {
  color: var(--neutral-8);
  background-color: var(--neutral-2);
}