
.menu {
	position: fixed;
	width: 100%;
	bottom: 0;
	height: 60px;

	.no-target {
		line-height: 60px;
		text-align: center;
		user-select: none;
	}
	.q-tabs {
		height: 100%;
	}
}
