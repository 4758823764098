
.type {
	.btn {
		width: 48%;
		position: relative;

		img {
			display: inline-block;
			height: 20px;
		}
	}
}
.damage_inputs {
	display: grid;
	grid-template-columns: 80px 1fr 25px;
	grid-auto-rows: max-content;
	line-height: 38px;
	grid-gap: 4px;
	margin-bottom: 15px;

	.amount {
		text-align: center;
	}
	.handle {
		text-align: center;
	}
	.no-delete {
		grid-column: span 2;
	}
	.validate {
		grid-column: span 3;
		line-height: 20px;
		margin: 0;
	}
}
.healing-input {
	height: 90px;
	margin-bottom: 15px;
	font-size: 25px;
}
