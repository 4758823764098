
// Remove arrows from number field
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.loot {
	h2 {
		a {
			font-size: 15px;
		}
	}

	.currency {
		margin: auto;
		display: flex;
		justify-content: center;
		max-width: 400px;
		text-align: center;

		img {
			height: 30px;
			margin-bottom: 10px;
		}
		div {
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
