
.q-field__control {
	.q-item {
		width: 100%;
	}
}
.q-item.no-wrap {
	flex-wrap: wrap;
}
.timer {
	display: flex;

	.q-field {
		width: 100%;
	}
}
