:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
.shares {
  background-color: var(--neutral-6-transparent-7);
  height: 100%;
  backdrop-filter: blur(1px);
  border-left: solid 1px var(--neutral-11);
}
.shares .q-scrollarea {
  padding: 10px 10px 0 10px;
  height: 100%;
}
.shares .q-scrollarea ul.shared {
  padding: 0;
  list-style: none;
  margin: 0 0 20px 0;
}
.shares .q-scrollarea ul.shared li {
  border-bottom: solid 1px var(--neutral-2);
  padding: 5px 0;
}
.shares .q-scrollarea ul.shared li .targets {
  display: flex;
  justify-content: center;
  height: 25px;
  margin: 10px 0;
}
.shares .q-scrollarea ul.shared li .targets .img {
  width: 23px;
  height: 23px;
  margin: 0 2px;
}
.shares .q-scrollarea ul.shared li .targets .img .avatar {
  font-size: 16px;
}
.shares .q-scrollarea ul.shared li.roll h3 {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  display: grid;
  grid-template-columns: 43px auto;
  grid-gap: 5px;
  padding-right: 8px;
  background-color: var(--neutral-8);
  margin: 0 !important;
  font-size: 15px;
  height: 43px;
}
.shares .q-scrollarea ul.shared li.roll h3 .img {
  width: 42px;
  height: 42px;
}
.shares .q-scrollarea ul.shared li.roll h3 .img.logo {
  background: none;
  padding: 3px;
}
.shares .q-scrollarea ul.shared li.roll h3 .img .avatar {
  font-size: 33px;
}
.shares .q-scrollarea ul.shared li.roll h3 .header {
  display: flex;
  flex-flow: column wrap;
}
.shares .q-scrollarea ul.shared li.roll h3 .header .name {
  padding-top: 2px;
  flex: 3 1 0%;
  line-height: 18px;
  height: 16px;
  font-style: italic;
  font-size: 12px;
  width: calc(100% - 10px);
}
.shares .q-scrollarea ul.shared li.roll h3 .header .title {
  flex: 4 1 0%;
  line-height: 18px;
  height: 20px;
  color: var(--neutral-1);
  width: calc(100% - 10px);
}
.shares .q-scrollarea ul.shared li.roll h3 .header .title:only-child {
  line-height: 42px;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  padding: 8px;
  background-color: var(--neutral-6);
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper .result {
  font-size: 18px;
  height: 35px;
  line-height: 35px;
  display: grid;
  grid-template-columns: auto min-content;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper .result .roll {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper .result .total {
  text-align: right;
  white-space: nowrap;
  font-size: 25px;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper.action .result {
  padding: 3px 8px;
  background: var(--neutral-8);
  margin-bottom: 5px;
  line-height: 29px;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper.action .result:last-child {
  margin-bottom: 0;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper.action .result.toHit {
  border-bottom: solid 1px var(--neutral-4);
  background: none;
  padding: 0 3px 5px 3px;
  height: 35px;
  line-height: 30px;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper.action .result.toHit .roll {
  display: flex;
}
.shares .q-scrollarea ul.shared li.roll .result-wrapper.action .result.toHit .roll .icon {
  display: inline-block;
  margin-right: 8px;
  width: 30px;
  height: 30px;
}
.shares .q-scrollarea ul.shared li.xp {
  color: var(--neutral-1);
  text-align: center;
  font-size: 25px;
}

@media only screen and (max-width: 576px) {
  .shares {
    background: none;
    height: calc(100vh - 170px);
    border: none;
  }
  .shares .q-scrollarea {
    padding: 10px 5px 0 0;
  }
}