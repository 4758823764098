:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
label.group {
  display: block;
  line-height: 30px;
  margin-bottom: 10px;
  border-bottom: solid 1px var(--neutral-5);
  width: 100%;
}

h3 {
  border-bottom: solid 1px var(--neutral-4);
  font-size: 15px;
  margin-bottom: 1px;
}

.accordion {
  margin-bottom: 20px;
}

.hk-card.rolls {
  margin-bottom: 0;
}
.hk-card.rolls .card-header {
  padding: 12px 10px;
  margin-bottom: 1px;
}
.hk-card.rolls .roll {
  cursor: default;
}
.hk-card.rolls .roll span:hover {
  color: var(--neutral-2);
}

.limit .q-field,
.limit > span {
  width: 100%;
}
.limit .limit-type {
  width: 150px;
  margin-left: 1px;
}

.range .q-field {
  width: 50%;
}
.range .reach {
  width: calc(50% - 1px);
  margin-right: 1px;
}

.roll-button {
  display: inline-block;
  cursor: pointer;
  background-image: url("../../assets/_img/logo/logo-icon-no-shield-cyan.svg");
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: cover;
  vertical-align: -5px;
  user-select: none;
}

.advantage .roll-button:hover {
  background-image: url("../../assets/_img/logo/logo-icon-no-shield-green.svg");
}

.disadvantage .roll-button:hover {
  background-image: url("../../assets/_img/logo/logo-icon-no-shield-red.svg");
}

.drag-ghost {
  opacity: 0.6;
}
.drag-handle {
  color: var(--neutral-2);
  cursor: grab;
}
.drag-dragging {
  opacity: 1 !important;
  margin-top: -42px !important;
  margin-left: -40px !important;
  box-shadow: 0 5px 10px var(--black);
  background-color: var(--neutral-9);
}

.action-list {
  transition: transform 0.5s;
}

::v-deep .q-item {
  user-select: none;
}