:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
h2 {
  margin-bottom: 5px !important;
}
h2 small {
  font-size: 12px;
}

h3 {
  text-transform: none;
  font-weight: bold !important;
  border-bottom: solid 1px var(--neutral-4);
  padding-bottom: 3px;
}

a {
  color: var(--neutral-2) !important;
}

.abilities {
  user-select: none;
  display: grid;
  grid-template-columns: repeat(6, 40px);
  grid-column-gap: 15px;
  text-align: center;
  font-size: 12px;
  max-width: 650px;
}
.abilities .abilityName {
  font-size: 15px;
  font-weight: bold;
}
.abilities .ability {
  cursor: pointer;
}
.abilities .advantage .ability:hover {
  color: var(--green);
}
.abilities .disadvantage .ability:hover {
  color: var(--red);
}

.caster-info {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.caster-info .value {
  font-size: 18px;
  font-weight: bold;
  color: var(--neutral-1);
}

.monster-action {
  margin-bottom: 20px;
}
.monster-action-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}
.monster-action-title__name {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.monster-action-title__name .slots {
  cursor: pointer;
}
.monster-action-title .roll-button {
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
  background-image: url("../../assets/_img/logo/logo-icon-no-shield-cyan.svg");
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: cover;
  vertical-align: -5px;
  user-select: none;
}
.monster-action-title .advantage .roll-button:hover {
  background-image: url("../../assets/_img/logo/logo-icon-no-shield-green.svg");
}
.monster-action-title .disadvantage .roll-button:hover {
  background-image: url("../../assets/_img/logo/logo-icon-no-shield-red.svg");
}

.playerSkills {
  user-select: none;
  column-count: 3;
  column-gap: 20px;
  column-rule: 1px solid var(--neutral-5);
}
.playerSkills .playerSkill {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 5px;
  cursor: pointer;
}
.playerSkills .playerSkill:hover {
  color: var(--neutral-1);
}
.playerSkills .playerSkill i.fa-circle {
  margin: 0 3px;
  font-size: 6px;
  vertical-align: 2px;
}
.playerSkills .advantage .playerSkill:hover {
  color: var(--green);
}
.playerSkills .disadvantage .playerSkill:hover {
  color: var(--red);
}

.skills .skill::after,
.saves .hk-roll::after {
  content: ", ";
}
.skills .skill:last-child::after,
.saves .hk-roll:last-child::after {
  content: "";
}

.saves {
  user-select: none;
}
.saves .save {
  cursor: pointer;
}
.saves .advantage .save:hover {
  color: var(--green);
}
.saves .disadvantage .save:hover {
  color: var(--red);
}

.smallWidth .abilities {
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto auto;
  grid-row-gap: 15px;
}
.smallWidth .playerSkills {
  column-count: 2;
}