
h1,
h2 {
	margin-bottom: 5px !important;
}
.data {
	line-height: 25px;

	.type {
		display: inline-block;
		width: 150px;
	}
}
