
h4 {
	font-size: 16px;
	margin: 15px 0 !important;
	padding-bottom: 1px;
	border-bottom: solid 1px #5c5757;
	display: flex;
	justify-content: space-between;
	line-height: 30px;
}
